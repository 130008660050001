/* src/HomePage.css */

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Ensure it takes the full width */
    box-sizing: border-box;
    padding: 20%;
    /* Remove padding to ensure no overflow */
    overflow: hidden;
    /* Prevent scrolling */
}


.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    gap: 80px;
    /* Add more space between the buttons */
}

.button {
    background-color: black;
    /* Set button background color to black */
    color: white;
    /* Set button text color to white */
    border-radius: 50px;
    /* Make the button round */
    padding: 20px 40px;
    /* Increase padding for a larger button */
    font-size: 20px;
    /* Increase font size */
    border: none;
    /* Remove default border */
    cursor: pointer;
    /* Change cursor to pointer on hover */
    transition: background-color 0.3s;
    /* Add transition for background color */
}

.button:hover {
    background-color: darkgray;
    /* Change background color on hover */
}