/* src/WeeklyRankPage.css */
.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px;
    /* Increase padding for more spacing */
    box-sizing: border-box;
}

.table-container {
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 40px;
    /* Add extra padding to the bottom */
    background-color: rgba(0, 0, 0, 0.8);
    /* Black background with some transparency */
    border-radius: 10px;
    width: 80%;
    /* Adjust the width as needed */
    max-width: 90%;
    /* Set a maximum width */
    height: 80%;
    /* Set a relative height */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Add some shadow for better visibility */
    overflow: auto;
    /* Enable scrolling */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    color: white;
    /* Set text color to white */
}

.styled-table th,
.styled-table td {
    padding: 16px;
    /* Increase padding for better readability */
    border: 1px solid #ddd;
    text-align: left;
    color: white;
    /* Ensure text color is white in table cells */
}

.styled-table th {
    background-color: rgba(255, 255, 255, 0.1);
    /* Slightly transparent white background for header */
}

.styled-table tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
    /* Slightly transparent white background for even rows */
}

.styled-table tr:hover {
    background-color: rgba(255, 255, 255, 0.2);
    /* Slightly transparent white background for hover effect */
}