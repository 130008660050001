/* src/DailyRankPage.css */
.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px;
    /* Increase padding for more spacing */
    box-sizing: border-box;
    color: white;
    /* Ensure text color is white */
}

.page-header {
    font-size: 2.5em;
    /* Increase font size for better visibility */
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    /* Center align the text */
    color: white;
    /* Ensure text color is white */
}